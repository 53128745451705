@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-upload {
  max-width: max(1000px, 80%);
  margin: auto;
  margin-top: 32px;
  padding: 28px;
  background-color: white;

  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }

  #upload-form {
    max-width: max(1000px, 70%);
    margin: auto;
    padding: 16px;
    text-align: center;

    @media screen and (max-width: 1440px) {
      width: 60%;
    }
    @media screen and (max-width: 1280px) {
      width: 70%;
    }
    @media screen and (max-width: 810px) {
      width: 100%;
    }

    .ant-upload-wrapper {
      .ant-upload-drag {
        background-color: #e3e3e3;
      }
      .ant-upload-text {
        color: #000;
        font-size: 20px;
        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
      }
      .ant-upload-drag-icon {
        .anticon-spin {
          font-size: 64px;
          @media screen and (max-width: 1280px) {
            font-size: 56px;
          }
        }
        svg {
          width: 64px;
          height: 64px;
          fill: #000;
          @media screen and (max-width: 1280px) {
            width: 56px;
            height: 56px;
          }
        }
      }
    }

    .ant-btn-primary {
      min-width: 180px;
      min-height: 50px;
    }
    .ant-upload {
      padding: 24px 16px;
    }

    .ant-upload-list-item {
      margin-top: 12px;
      justify-content: center;
      text-align: center;

      .ant-upload-list-item-name {
        color: #000;
        font-size: 18px;
        max-width: max-content;
      }

      .ant-upload-list-item-actions {
        .ant-btn {
          opacity: 1;
        }
        .anticon-delete {
          margin-left: 16px;
          svg {
            fill: #000;
          }
        }
      }
    }
  }

  .detail-additional {
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
