@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.energy-address {
  max-width: max(1000px, 80%);
  margin: auto;
  margin-top: 32px;
  padding: 28px;
  background-color: white;

  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }

  .map-wrapper {
    margin-top: 16px;
    width: 100%;
    height: 480px;
    .leaflet-container {
      height: 100%;
    }
    .label-container--selected {
      outline: 2px solid #000;
    }
  }

  .autocomplete-item {
    border: 1px solid #dedede;
    background: #ffffffe5;
    padding: 28px 20px;
    border-radius: 30px;
    max-width: 317px;

    .autocomplete-item__title {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
    }

    .autocomplete-item__content {
      li:last-child {
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: unset;
          white-space: unset;
        }
      }
    }
  }
  .ant-skeleton {
    height: 176px;
    &.full {
      width: 100% !important;
      min-height: 400px;

      .ant-skeleton-image {
        width: 100%;
      }
    }
    .ant-skeleton-image {
      height: 100%;
      min-width: 300px;
    }
  }
  .slick-slider .slick-slide {
    padding: 0 12px;
  }
  .slick-arrow {
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: #000;
      }
    }
  }

  .info-box {
    text-align: center;
    margin-bottom: 32px;
    font-size: 16px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    padding: 12px;
  }

  .ant-empty .ant-empty-image {
  }
}
