@import "styles/definition/text.scss";
@import "styles/definition/variables.scss";

.btn-disconnect {
  background-color: #dc2626 !important;

  &:hover {
    background-color: #b91c1c !important;
  }
}

.energy-account {
  margin: 0 auto;
  padding: 20px;
  padding-top: 48px;
  background-color: white;
  min-height: calc(100vh - 64px);

  .energy-account__content {
    max-width: 600px;
    margin: auto;

    h2 {
      letter-spacing: -0.025em;
      margin-bottom: 24px;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
    }

    .personal-info {
      background-color: rgb(245, 245, 245);
      padding: 24px;
      border-radius: 8px;
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .profile-options {
      .option-item {
        background-color: rgb(245, 245, 245);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 16px;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgb(235, 235, 235);
        }

        label {
          cursor: pointer;
        }

        input[type="radio"] {
          accent-color: #1e6862;
        }

        .option-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 8px;
        }

        .option-description {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }

      #account-form {
        width: 100%;
        margin: auto;
        margin-bottom: 32px;

        .input-stack {
          display: flex;
          flex-direction: column;
          gap: 16px;

          input {
            background-color: rgb(245, 245, 245);
            width: 100%;
            height: 52px;
            border-radius: 8px;
            padding: 0 16px;
            font-size: 16px;
            border: 1px solid transparent;
            transition: all 0.2s ease;

            &:focus {
              outline: none;
              border-color: #1e6862;
              background-color: rgb(235, 235, 235);
            }
          }
        }
      }

      .name-inputs {
        margin-bottom: 32px;

        .input-group {
          display: flex;
          gap: 16px;
          margin-top: 0;

          .form-input {
            background-color: rgb(245, 245, 245);
            width: 100%;
            height: 52px;
            border-radius: 8px;
            padding: 0 16px;
            font-size: 16px;
            border: 1px solid transparent;
            transition: all 0.2s ease;

            &:focus {
              outline: none;
              border-color: #1e6862;
              background-color: rgb(235, 235, 235);
            }
          }
        }

        .input-stack {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .btn-update {
        background-color: #1e6862;
        color: white;
        width: 100%;
        height: 52px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        margin-top: 32px;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgb(35, 122, 115);
        }
      }

      .btn-connect {
        background-color: #1e6862ff;
        border-radius: 8px;
        height: 52px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #fff;
        border: none;
        width: 100%;
        max-width: none;
        margin: 0 auto;
        display: block;
        cursor: pointer;

        &:hover {
          background-color: rgb(35, 122, 115);
          color: #fff;
        }
      }
    }
  }

  .snackbar {
    position: fixed;
    top: 84px;         // Changé de 20px à 84px pour apparaître sous le header
    right: 20px;
    transform: none;
    padding: 12px 24px;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &.success {
      background-color: #4caf50;
      color: white;
    }

    &.error {
      background-color: #f44336;
      color: white;
    }
  }
}
